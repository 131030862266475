* {
	margin: 0;
	padding: 0;
}

html {
	width: 100%;
	margin: 0 auto;
	max-width: 1920px;
	min-width: 1200px;
	font-family: 'Poppins Regular', 'PingFang SC Regular', 'Raleway Regular';
	position: relative;
	padding-bottom: 100px;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// add custom fonts
@font-face {
	font-family: 'PingFang SC Regular';
	src: url('/fonts/PingFang SC Regular.ttf');
	src: url('/fonts/PingFang SC Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'PingFang SC Ultralight';
	src: url('/fonts/PingFang SC Ultralight.ttf');
	src: url('/fonts/PingFang SC Ultralight.ttf') format('truetype');
}
@font-face {
	font-family: 'PingFang SC Semibold';
	src: url('/fonts/PingFang SC Semibold.ttf');
	src: url('/fonts/PingFang SC Semibold.ttf') format('truetype');
}
@font-face {
	font-family: 'Raleway Regular';
	src: url('/fonts/Raleway Regular.ttf');
	src: url('/fonts/Raleway Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Raleway ExtraLight';
	src: url('/fonts/Raleway ExtraLight.ttf');
	src: url('/fonts/Raleway ExtraLight.ttf') format('truetype');
}
@font-face {
	font-family: 'Poppins Regular';
	src: url('/fonts/Poppins Regular.ttf');
	src: url('/fonts/Poppins Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'Poppins ExtraBold';
	src: url('/fonts/Poppins ExtraBold.ttf');
	src: url('/fonts/Poppins ExtraBold.ttf') format('truetype');
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
