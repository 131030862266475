#responsibility {
	.responsibility-banner {
		display: flex;
		position: relative;
		justify-content: center;
		margin-bottom: -10vw;
		z-index: 9;
		img {
			height: 100%;
			width: 100%;
		}
		.title {
			font-family: 'Raleway ExtraLight', 'PingFang SC Ultralight';
			font-weight: 100;
			font-size: 90px;
			line-height: 126px;
			letter-spacing: 7.5px;
			color: #f2f7f9;
			position: absolute;
			top: 30%;
		}
	}
	@media screen and (max-width: 1300px) {
		.responsibility-banner {
			.title {
				font-size: 50px;
			}
		}
	}
	@media screen and (min-width: 1921px) {
		.responsibility-banner {
			margin-bottom: -7.5vw;
		}
	}

	.responsibility-body1,
	.responsibility-body2,
	.responsibility-body3,
	.responsibility-body1-zh {
		position: relative;
		.content {
			width: 100%;
			position: absolute;

			.title-en {
				width: 60%;
				color: #2d5d72;
				font-family: 'Poppins SemiBold';
				font-size: 48px;
				font-weight: 600;
				letter-spacing: 4px;
				line-height: 72px;
				text-align: center;
				margin: 0 auto;
				padding-top: 20px;
			}
			.title-zh {
				width: 60%;
				color: #2d5d72;
				font-family: 'PingFang SC Semibold';
				font-size: 48px;
				font-weight: 600;
				letter-spacing: 4px;
				line-height: 67px;
				text-align: center;
				margin: 0 auto;
			}
			.info-en {
				width: 40%;
				color: #4d4d4e;
				font-family: 'Poppins Regular';
				font-size: 16px;
				letter-spacing: 0;
				line-height: 25px;
				text-align: left;
				margin: 20px auto;
			}
			.info-zh {
				width: 40%;
				color: #4d4d4e;
				font-family: 'PingFang SC Regular';
				font-size: 18px;
				letter-spacing: 0;
				line-height: 25px;
				margin: 20px auto;
			}
			.images {
				width: 40%;
				margin: 2% auto;

				.img2 {
					float: right;
				}
			}
			@media screen and (max-width: 1919px) {
				.info-en,
				.info-zh {
					width: 50%;
				}
				.images {
					width: 50%;
				}
			}
			@media screen and (max-width: 1560px) {
				.images {
					width: 50%;
					.img1,
					.img2 {
						width: 45%;
					}
				}
			}
			@media screen and (max-width: 1360px) {
				.info-en,
				.info-zh {
					width: 60%;
				}
			}
		}
	}

	.responsibility-body1 {
		.content {
			top: 100px;
		}
		height: 1000px;
		background-image: url('/images/wave/responsi-1.png');
		background-repeat: no-repeat;
		background-size: 100% 90%;
		margin-bottom: -200px;
		z-index: 8;
	}
	.responsibility-body1-zh {
		.content {
			top: 100px;
		}
		height: 850px;
		background-image: url('/images/wave/responsi-1.png');
		background-repeat: no-repeat;
		background-size: 100% 90%;
		margin-bottom: -200px;
		z-index: 8;
	}
	.responsibility-body2 {
		.content {
			top: 150px;
		}
		height: 1200px;
		background-image: url('/images/wave/responsi-2.png');
		background-repeat: no-repeat;
		background-size: 100% 70%;
		margin-bottom: -300px;
		z-index: 7;
	}
	.responsibility-body3 {
		height: 600px;
		margin-bottom: 50px;
		.content {
			top: -50px;
		}
	}
}
